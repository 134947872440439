import React from 'react'

const shortContent = content => {
  if (content) {
    return content.length > 100 ? `${content.slice(0, 100)}…` : content
  }

  return ''
}

const RecipeItem = props => {
  // New API destructure:
  const {
    recipe: {
      image_small: image,
      name,
      // average_rating,
      // rating_count: ratingsCount,
      description,
      creator: {
        first_name: firstName,
        last_name: lastName,
        image_small: userImage,
      },
    },
  } = props

  return (
    <div className="column is-one-third">
      <div className="card ">
        <div className="card-image">
          <figure className="image is-4by3">
            <img src={image} alt="" />
          </figure>
        </div>
        <div className="card-content">
          <p className="title is-4">{name}</p>

          <div className="media">
            <div className="media-left">
              <figure className="image is-48x48">
                <img src={userImage} alt="" />
              </figure>
            </div>
            <div className="media-content">
              <p className="subtitle is-6">
                By: {firstName} {lastName}
              </p>
            </div>
          </div>

          <div className="content">{shortContent(description)}</div>
        </div>
      </div>
    </div>
  )
}

export const RecipeFieldPreview = props => {
  const { value } = props

  if (value) {
    return <RecipeItem recipe={value} />
  }
  return null
}
